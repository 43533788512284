import type { IContentD2G } from '../../constants/DownloadToGo';
import { getLocationStateContext } from '../../helpers/contents/contents-helper';
import { LinkerData } from '../Linker/types';

export function getContentGridItemLinkerData(data: IContentD2G): LinkerData {
  if (!data.onClick) {
    return { ...data };
  }

  const { onClick } = data;

  return { ...data, mainOnClick: onClick, context: getLocationStateContext(data.onClick.displayTemplate) };
}
