import { useSelector } from 'react-redux';
import { featUpcomingHodorStickerSelector } from '../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { profileIdSelector } from '../../store/slices/user-selectors';
import ContentGridTemplateItem, { type ContentGridTemplateItemProps } from './ContentGridTemplateItem';

export function ContentGridTemplateItemConnected(
  props: Omit<ContentGridTemplateItemProps, 'isTvDevice' | 'profileId' | 'token'>
): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const profileId = useSelector(profileIdSelector);
  const isFeatUpcomingHodorSticker = useSelector(featUpcomingHodorStickerSelector);

  return (
    <ContentGridTemplateItem
      {...props}
      isTvDevice={isTvDevice}
      profileId={profileId}
      isFeatUpcomingHodorSticker={isFeatUpcomingHodorSticker}
    />
  );
}
