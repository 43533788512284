import {
  ButtonLogo,
  DeviceToImageUrl,
  Media,
  MediaFooter,
  MediaOverlay,
  ThumborSourcesDimensions,
} from '@canalplus/dive';
import { DIMENSIONS, ImageSize, Ratio } from '@canalplus/mycanal-commons';
import { PosterPlaceholder } from '@canalplus/mycanal-sharedcomponent';
import { PersoLists, TitleDisplayMode } from '@canalplus/sdk-hodor';
import { useSelector } from 'react-redux';
import IconPlaceHolderActor from '../../assets/svg/placeholderactor.svg';
import IconPlaceHolderActor34 from '../../assets/svg/placeholderactor34.svg';
import type { IContentD2G } from '../../constants/DownloadToGo';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { getHodorSticker } from '../../helpers/stickers/stickers-helper';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import CardConnected from '../Card/CardConnected';
import PlayBackIcon from '../Card/PlayBackIcon/PlayBackIcon';
import { CardProps } from '../Card/types';
import Linker from '../Linker/Linker';
import Sticker from '../Sticker/Sticker';
import ThumborMediaImage from '../ThumborMediaImage/ThumborMediaImage';
import styles from './ContentGridTemplateItem.css';
import { getContentGridItemLinkerData } from './helper';

/**
 * ContentGridTemplateItem
 *
 * Component used in <ContentGridTemplate /> to render its children
 *
 * @param {object}   content                data from TMS to render component
 * @param {number}   idItem                 id of items
 * @param {number}   profileId              current profile id
 * @param {string}   ratio                  ratio used for image sizes
 * @param {string}   listType               list type of contentGrid
 * @param {func}     onClickRemove          remove content on click
 * @param {func}     openOfflinePlayer      open player offline
 * @param {number}   profileId              profile ID
 * @param {number}   ratio                  ratio of content
 */

export type ContentGridTemplateItemProps = {
  content: IContentD2G | null;
  isDark?: boolean;
  idItem?: string;
  imageSize: ImageSize;
  isPlayBackIconShown?: boolean;
  isRemovableItem?: boolean;
  isFeatUpcomingHodorSticker?: boolean;
  isTvDevice: boolean;
  listType?: PersoLists;
  onClickRemove?: (event: React.MouseEvent, contentID: string, listType: PersoLists) => void;
  profileId?: number;
  ratio: Ratio;
  titleDisplayMode: TitleDisplayMode;
  shouldRenderWithComponentMedia?: boolean;
  isSearch?: boolean;
};

function ContentGridTemplateItem({
  content,
  isDark,
  imageSize,
  listType,
  onClickRemove,
  ratio,
  titleDisplayMode,
  isRemovableItem,
  isPlayBackIconShown,
  isFeatUpcomingHodorSticker,
  shouldRenderWithComponentMedia = false,
  isSearch = false,
}: ContentGridTemplateItemProps): JSX.Element {
  const {
    contentID,
    altImage,
    endTime,
    imageSpecificities,
    isInOffer = true,
    isLogoTyped = false,
    lastDays,
    size = 0,
    startTime,
    subtitle,
    title = '',
    type,
    URLImage,
    URLImageForDarkMode = URLImage,
    URLImageForLightMode = URLImage,
    URLLogoChannel = '',
    altLogoChannel,
    userProgress,
    stickers,
    isTVoD,
  } = content || {};

  const publicConfig = getPublicConfig();

  const isArticle = type === publicConfig.TEMPLATE.CREATIVE_MEDIA;

  const onGoing = startTime ? { startTime, endTime } : undefined;

  const displayedTitle = titleDisplayMode !== TitleDisplayMode.None ? title : '';
  const displayedSubtitle =
    titleDisplayMode !== TitleDisplayMode.None && titleDisplayMode !== TitleDisplayMode.TitleOnly ? subtitle : '';

  const isLogoTypedModeAndTitleNotIncrusted = titleDisplayMode === TitleDisplayMode.LogoTyped && !isLogoTyped;

  const isLogoChannel = imageSpecificities === 'isLogo';

  // if personality, show the correct placeHolder (for search)
  const isPersonality = type === publicConfig.search_results.personality;

  const imageLightMode = !isPersonality ? URLImageForLightMode : '';
  const imageDarkMode = !isPersonality ? URLImageForDarkMode : '';

  const image = { default: isDark ? imageDarkMode : imageLightMode } satisfies DeviceToImageUrl;

  const dimensions = DIMENSIONS[ratio][imageSize] satisfies ThumborSourcesDimensions;

  const isBottomTitle = !([TitleDisplayMode.LogoTyped, TitleDisplayMode.None] as string[]).includes(titleDisplayMode);

  const sticker = getHodorSticker(stickers, isFeatUpcomingHodorSticker);

  const ariaLabel = title;

  //  this code line 136 - 143  will be deleted during the installation of the media in the search with this ticket in progress https://gitlab.canal-plus.com/dce-front/one-webapp/one-webapp/-/issues/11980
  //  this code was added just for the 5.41 fix https://gitlab.canal-plus.com/dce-front/one-webapp/one-webapp/-/issues/12000
  const isTvDevice = useSelector(displayTVModeSelector);
  const icon = ratio === Ratio.Ratio34 ? <IconPlaceHolderActor34 /> : <IconPlaceHolderActor />;
  const posterPlaceholder = <PosterPlaceholder loader={false} svg={icon} className={styles.actorPlaceholder} />;
  const svg = isTvDevice ? (
    <div className={styles.actorPlaceholderWrapper}>{posterPlaceholder}</div>
  ) : (
    posterPlaceholder
  );

  const cardProps = {
    altImage,
    ariaLabel,
    dimensions,
    image,
    imageSpecificities,
    isArticle,
    isInOffer,
    isLogoTyped,
    logoChannel: { default: URLLogoChannel },
    altLogoChannel,
    onGoing,
    ratio,
    size,
    subtitle: displayedSubtitle,
    title: displayedTitle,
    titleDisplayMode,
    userProgress,
    isPlayBackIconShown,
    // this code will be deleted by https://gitlab.canal-plus.com/dce-front/one-webapp/one-webapp/-/issues/11980
    ...(isPersonality && { svg }),
    sticker,
  } satisfies CardProps;

  const getOverlayIcon = (): JSX.Element | undefined => {
    if (isPlayBackIconShown && !isTVoD) {
      return <PlayBackIcon key="playbackicon" />;
    }

    return undefined;
  };

  if (content) {
    const overlayIcon = getOverlayIcon();

    const getPersonalityIcon = () => {
      const personalityIcon = ratio === Ratio.Ratio34 ? <IconPlaceHolderActor34 /> : <IconPlaceHolderActor />;
      return isPersonality && !URLImage ? personalityIcon : undefined;
    };

    return (
      <div className={isLogoChannel ? 'contentGridChannels' : 'contentGridItems'}>
        <Linker
          data={getContentGridItemLinkerData(content)}
          title={displayedTitle}
          className={styles.contentGridTemplateItem__onClick}
        >
          {isLogoChannel && !isSearch ? (
            <ButtonLogo
              as="div"
              logo={<ThumborMediaImage url={image.default} dimensions={dimensions} alt={altImage} height="auto" />}
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {shouldRenderWithComponentMedia && !isArticle ? (
                <Media
                  aspectRatio={ratio}
                  aria-label={ariaLabel}
                  image={
                    URLImage ? (
                      <ThumborMediaImage dimensions={dimensions} url={URLImage} alt={altImage} />
                    ) : (
                      getPersonalityIcon()
                    )
                  }
                  overlay={
                    <MediaOverlay
                      logo={
                        URLLogoChannel ? (
                          <ThumborMediaImage
                            dimensions={DIMENSIONS.LOGO_CHANNEL.normal}
                            url={URLLogoChannel}
                            alt={altLogoChannel}
                          />
                        ) : undefined
                      }
                      title={isLogoTypedModeAndTitleNotIncrusted ? displayedTitle : undefined}
                      subtitle={displayedSubtitle}
                      sticker={
                        <Sticker
                          sticker={sticker}
                          variant={lastDays ? 'last-days' : undefined}
                          startTime={startTime}
                          endTime={endTime}
                        />
                      }
                      buttonIcon={overlayIcon}
                    />
                  }
                  footer={
                    isBottomTitle ? <MediaFooter title={displayedTitle} subtitle={displayedSubtitle} /> : undefined
                  }
                />
              ) : (
                <CardConnected
                  {...cardProps}
                  contentID={contentID}
                  isRemovableItem={isRemovableItem}
                  lastDays={lastDays}
                  listType={listType}
                  onClickRemove={onClickRemove}
                />
              )}
            </>
          )}
        </Linker>
      </div>
    );
  }

  return <CardConnected {...cardProps} />;
}

export default ContentGridTemplateItem;
