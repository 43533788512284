import classNames from 'classnames';
import truncate from 'lodash/truncate';
import { useDiveContext } from '../../../context';
import { MediaFooterProps } from './MediaFooter.types';

const ELLIPSIS = '…';
export const MAX_DESCRIPTION_LENGTH = 250;

/**
 * Renders `Media` related editorial content
 *
 * | Content       | Overflows to `...` when |
 * |---------------|-------------------------|
 * | `title`       | > 2 lines               |
 * | `subtitle`    | > 2 lines               |
 * | `description` | > 250 characters        |
 *
 * @example
 * <MediaFooter
 *   title="Avatar: The Way of Water"
 *   subtitle='Return to Pandora.'
 *   description='Learn the story of the Sully family'
 *  />
 */
export function MediaFooter({ title, subtitle, description }: MediaFooterProps): JSX.Element | null {
  const { isTv } = useDiveContext();

  if (!(title || subtitle || description)) {
    return null;
  }

  return (
    <div
      className={classNames(
        'mt-8 font-hind',
        // ! tailwind-base-styles
        '[&>p]:mb-0'
      )}
    >
      {title && (
        <p
          className={classNames(
            `text-op100 overflow-hidden line-height-2 line-clamp-2 text-ellipsis`,
            isTv ? 'text-30' : 'text-16',
            // ! tailwind-base-styles
            'mt-0'
          )}
        >
          {title}
        </p>
      )}
      {subtitle && (
        <p className={classNames('text-op50 mt-2 line-clamp-2 text-ellipsis', isTv ? 'text-27' : 'text-14 ')}>
          {subtitle}
        </p>
      )}
      {description && (
        <p
          className={isTv ? 'text-27 text-op50 mt-8' : 'text-14 text-op80 mt-6'}
          data-testid="media-footer-description-id"
        >
          {truncate(description, { length: MAX_DESCRIPTION_LENGTH, omission: ELLIPSIS })}
        </p>
      )}
    </div>
  );
}
