import { DisplayMode, ImageSize, Ratio } from '@canalplus/mycanal-commons';
import { ContentRowHeader } from '@canalplus/mycanal-sharedcomponent';
import { Binder, MiddlewareFactory } from '@canalplus/one-navigation';
import { PersoLists, TitleDisplayMode } from '@canalplus/sdk-hodor';
import {
  ApiV2ExpertModeHighlightContent,
  ApiV2ExpertModeHighlightSpyroContent,
  ApiV2ExpertModeTracking,
} from '@dce-front/hodor-types';
import { InfiniteData } from '@tanstack/react-query';
import classNames from 'classnames/bind';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ContentGridTemplateItemConnected } from '../../../components/ContentGrid/ContentGridTemplateItemConnected';
import HeadingIdentityV5 from '../../../components/HeadingIdentityV5/HeadingIdentityV5';
import { IdentityCover, isCoverV5 } from '../../../components/IdentityCover/IdentityCover';
import LazyLoader from '../../../components/LazyLoader/LazyLoader';
import Spinner from '../../../components/Spinner/Spinner';
import TemplateHeaderCanal from '../../../components/TemplateHeader/TemplateHeader';
import { getLazyLoaderContentGridOptions } from '../../../constants/strates';
import { useTracking } from '../../../helpers/hooks/useTracking';
import { MIDDLEWARE_STRATE } from '../../../helpers/oneNavigation/middleware';
import { isDarkModeSelector } from '../../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { ContentGridState } from '../../../templates/ContentGrid/data/types';
import { ContentGridPersoState } from '../../../templates/ContentGridPerso/data/types';
import { ISearch } from '../../../templates/Search/data/types';
import getOverlappingClassnameWithCoverV5 from '../../../templates/helpers/IdentityV5Helpers/getOverlappingClassname';
import styles from './ContentGridTemplate.css';

const cx = classNames.bind(styles);

export type ContentGridTemplateProps = {
  data?:
    | InfiniteData<ContentGridPersoState>
    | InfiniteData<ContentGridState>
    | InfiniteData<ISearch>
    | Pick<
        InfiniteData<{
          tracking?: ApiV2ExpertModeTracking;
          contents?: ApiV2ExpertModeHighlightSpyroContent[] | ApiV2ExpertModeHighlightContent[];
        }>,
        'pages'
      >;
  header?: ContentRowHeader;
  imageRatio?: Ratio;
  imageSize?: ImageSize;
  isFetchingNextPage?: boolean;
  isFromDetail?: boolean;
  isRemovableItem?: boolean;
  isSearch?: boolean;
  listType?: PersoLists;
  middleware?: MiddlewareFactory[];
  onClickRemove?: (event: React.MouseEvent, contentID: string, listType: PersoLists) => void;
  titleDisplayMode?: TitleDisplayMode;
  onFocusable?: () => void;
  classNameGridItem?: string;
  shouldRenderWithComponentMedia?: boolean;
};

function ContentGridTemplate({
  data,
  header,
  imageRatio = Ratio.Ratio169,
  imageSize = 'normal',
  isFetchingNextPage = false,
  isFromDetail = false,
  isRemovableItem = false,
  isSearch = false,
  listType,
  middleware,
  onClickRemove,
  titleDisplayMode = TitleDisplayMode.All,
  onFocusable,
  classNameGridItem,
  shouldRenderWithComponentMedia = false,
}: ContentGridTemplateProps): JSX.Element {
  const isDark = useSelector(isDarkModeSelector);
  const { title, subtitle, button } = header || {};

  const { sendTracking } = useTracking();
  const isTvDevice = useSelector(displayTVModeSelector);

  const [firstPage] = data?.pages || [];
  const tracking = firstPage?.tracking;
  const resultsLength = firstPage?.contents?.length;

  useEffect(() => {
    if (onFocusable && resultsLength) {
      onFocusable();
    }
  }, [resultsLength, onFocusable]);

  const renderGrid = () => {
    const onClickProp =
      isSearch && tracking
        ? {
            onClick: () => sendTracking({ tracking, options: { routingContext: 'search' } }),
          }
        : {};

    const { increment, initialDisplayCount } = getLazyLoaderContentGridOptions(isTvDevice, isSearch);

    const items = (
      <LazyLoader initialDisplayCount={initialDisplayCount} loadMoreCount={increment}>
        {data?.pages?.map((page) => {
          return page.contents?.map((content) => {
            // DisplayMode.FULLSCREEN is without capital S -  waiting Hodor fix
            const isPlayBackIconShown = content.onClick?.displayMode?.toLowerCase() === DisplayMode.FULLSCREEN;

            return (
              <li
                className={cx(
                  'contentGrid__gridItem',
                  {
                    'contentGrid__gridItem--detailV5': isFromDetail,
                  },
                  classNameGridItem
                )}
                key={content?.hash}
                data-ratio={`${imageRatio}${imageSize}`}
                data-e2e="card"
                {...onClickProp}
              >
                <ContentGridTemplateItemConnected
                  content={content}
                  isDark={isDark}
                  imageSize={imageSize}
                  ratio={imageRatio}
                  titleDisplayMode={titleDisplayMode}
                  isRemovableItem={isRemovableItem}
                  onClickRemove={onClickRemove}
                  listType={listType}
                  isPlayBackIconShown={isPlayBackIconShown}
                  isSearch={isSearch}
                  shouldRenderWithComponentMedia={shouldRenderWithComponentMedia}
                />
              </li>
            );
          });
        })}
      </LazyLoader>
    );
    return items;
  };

  if (data?.pages?.[0] && 'cover' in data.pages[0] && isCoverV5(data.pages[0].cover)) {
    const { cover } = data.pages[0];

    return (
      <>
        <IdentityCover cover={cover} />
        <div
          className={cx(
            'contentGridTemplate',
            { 'contentGridTemplate--detailV5': isFromDetail },
            getOverlappingClassnameWithCoverV5(cover)
          )}
        >
          <Binder middleware={middleware || MIDDLEWARE_STRATE}>
            <div className={cx('contentGridTemplate__heading')}>
              <HeadingIdentityV5 cover={cover} title={title} />
            </div>
            <ul className={styles.contentGrid}>{renderGrid()}</ul>
            {isFetchingNextPage && (
              <div className={styles.spinner__content}>
                <Spinner size={5} />
              </div>
            )}
          </Binder>
        </div>
      </>
    );
  }

  // without cover v5
  return (
    <div className={cx('contentGridTemplate', { 'contentGridTemplate--detailV5': isFromDetail })}>
      <Binder middleware={middleware || MIDDLEWARE_STRATE}>
        {title && (
          <div className={cx('contentGrid__header', { 'contentGrid__header--detailV5': isFromDetail })}>
            <TemplateHeaderCanal button={button} isFromDetail={isFromDetail} subtitle={subtitle} title={title} />
          </div>
        )}
        <ul className={styles.contentGrid}>{renderGrid()}</ul>
        {isFetchingNextPage && (
          <div className={styles.spinner__content}>
            <Spinner size={5} />
          </div>
        )}
      </Binder>
    </div>
  );
}

export default ContentGridTemplate;
